<template>
    <section id="home">
        <div class="container-fluid top-banner">
            <b-row class="justify-content-md-center">
                <b-col class="text-center">
                    <h3 class="mt-5">Join India's Best Online Marketplace for</h3>
                    <h1>Premium Essential Products.</h1>
                    <b-row class="justify-content-md-center">
                        <b-col md="6">
                            <b-embed type="video" aspect="16by9" controls controlsList="nodownload" style="border: 10px solid #fff; border-radius: 5px; background-color: #fff;">
                                <source src="https://bexglobal.in/media/whats-new.mp4" type="video/mp4">
                            </b-embed>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-md-center">
                        <b-col md="8">
                            <b-row>
                                <b-col xs="2" class="item-text text-white mt-5">
                                    <div style="width: 25%; float: left">
                                        <img src="../../assets/five.png" class="img-fluid" alt="">
                                    </div>                                                   
                                    <div style="width: 70%; float: left; padding-left: 10px">
                                        5 Minute Signup Process
                                    </div>                                 
                                </b-col>
                                <b-col xs="2" class="item-text text-white mt-5">
                                    <div style="width: 25%; float: left">
                                        <img src="../../assets/settlement.png" class="img-fluid" alt="">
                                    </div>                                                   
                                    <div style="width: 70%; float: left; padding-left: 10px">
                                        Hassle-Free Settlements
                                    </div>                                 
                                </b-col>
                                <b-col xs="2" class="item-text text-white mt-5">
                                    <div style="width: 25%; float: left">
                                        <img src="../../assets/transparency.png" class="img-fluid" alt="">
                                    </div>                                                   
                                    <div style="width: 70%; float: left; padding-left: 10px">
                                        Transparent Distribution of money
                                    </div>                                 
                                </b-col>
                                <b-col xs="2" class="item-text text-white mt-5">
                                    <div style="width: 25%; float: left">
                                        <img src="../../assets/support.png" class="img-fluid" alt="">
                                    </div>                                                   
                                    <div style="width: 70%; float: left; padding-left: 10px">
                                        Vendor Support
                                    </div>                                 
                                </b-col>
                            </b-row>
                        </b-col>                        
                    </b-row>
                </b-col>
            </b-row>
        </div>
    </section>
</template>

<script>

export default {
    name: 'HomeBanner',
    components: {

    }
}
</script>