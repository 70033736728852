<template>
    <div>
        <b-navbar fixed="top" toggleable="lg" type="dark" variant="dark" class="bg-primary">
            <b-container>
                <b-navbar-brand>
                    <img src="../assets/Logo2.png" width="130" alt="">
                </b-navbar-brand>
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav class="ml-auto">
                        <b-nav-item to="/">Home</b-nav-item>
                        <b-nav-item to="/how-bex-works">How It Works?</b-nav-item>
                        <b-nav-item to="/calculator">Price Calculator</b-nav-item>
                        <b-nav-item href="https://vendor.bexcart.com/#/register" class="btn btn-primary btn-sm">Create Vendor Account</b-nav-item>                    
                    </b-navbar-nav>                    
                </b-collapse>
            </b-container>
        </b-navbar>
    </div>
</template>

<script>
export default {
    name: 'Navbar'
}
</script>

<style scoped>

</style>