import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { faCreditCard, faChartLine, faWallet, faTruck, faTags, faMoneyBillTransfer, faPercent, faHandHoldingDollar, faSackDollar, faFire } from '@fortawesome/free-solid-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faTags)
library.add(faCreditCard)
library.add(faChartLine)
library.add(faWallet)
library.add(faTruck)
library.add(faMoneyBillTransfer)
library.add(faPercent)
library.add(faFire)
library.add(faHandHoldingDollar)
library.add(faSackDollar)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
