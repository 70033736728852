<template>
  <div id="app">    
    <router-view/>
  </div>
</template>

<script>
export default {
  
}
</script>

<style>
@import url('./plugins/styles.css');
</style>
